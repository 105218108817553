var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('CUSTOMERS'))+" ")]),_c('v-btn',{staticClass:"ml-3 mt-1",attrs:{"icon":""},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.detailsDialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-plus-outline ")]),_vm._v(" "+_vm._s(_vm.$t('NEW_CUSTOMER'))+" ")],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card',{staticClass:"wd-100",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"py-4"},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t("SEARCH"),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"wd-100",attrs:{"multi-sort":"","search":_vm.search,"headers":_vm.customerTableHeaders,"items":_vm.list,"item-class":function (item) { return _vm.getTableRowClass(item); },"footer-props":{
          itemsPerPageOptions: [50, 100, 300, -1]
        }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}},{key:"item.cnpj",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.cnpj)+" ")])],1)],1)]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.email)+" ")])],1)],1)]}},{key:"item.acronym",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.acronym)+" ")])],1)],1)]}},{key:"item.allowFingerprintSignin",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.allowFingerprintSignin ? _vm.$t('ABLE') : _vm.$t('DISABLED'))+" ")])],1)],1)]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formattedDate(item.createdAt))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.formattedDateInWords(item.createdAt))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formattedDate(item.updatedAt))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.formattedDateInWords(item.updatedAt))+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{on:{"click":function($event){return _vm.handleOnClickEdit(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('EDIT_REGISTER')))])],1)],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","no-click-animation":""},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[(_vm.detailsDialog)?_c('v-card',{staticStyle:{"background-color":"var(--v-background-base)"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"dark":"","color":"primary"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},nativeOn:{"click":function($event){_vm.detailsDialog = false}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,2882372626)},[_c('span',[_vm._v(_vm._s(_vm.$t('CLOSE')))])]),_c('div',{staticClass:"text-h5 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('CUSTOMER'))+" ")])],1),_c('customers-details',{attrs:{"is-editing":_vm.isEditing,"customer-id":_vm.customerId},on:{"new-customer":_vm.getData,"edit-customer":_vm.handleOnConfirmEdit,"close":function($event){_vm.detailsDialog = false}}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }