<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='center'>
      <div class='text-h5'>
        {{$t('CUSTOMERS')}}
      </div>

      <v-btn icon class='ml-3 mt-1' @click='getData'>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn text @click='detailsDialog = true'>
        <v-icon class='mr-2'>
          mdi-account-plus-outline
        </v-icon>
        {{$t('NEW_CUSTOMER')}}
      </v-btn>
    </v-row>
    <v-divider class='my-3' />

    <v-row no-gutters>
      <v-card flat class='wd-100'>
        <v-card-title class='py-4'>
          <v-spacer />

          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            :label='$t("SEARCH")'
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          class='wd-100'
          multi-sort
          :search='search'
          :headers='customerTableHeaders'
          :items='list'
          :item-class='(item) => getTableRowClass(item)'
          :footer-props='{
            itemsPerPageOptions: [50, 100, 300, -1]
          }'
        >
          <template #item.name='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.cnpj='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.cnpj}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.email='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.email}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.acronym='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.acronym}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.allowFingerprintSignin='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.allowFingerprintSignin ? $t('ABLE') : $t('DISABLED') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.createdAt='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.createdAt)}}
                </div>
              </template>
              {{formattedDateInWords(item.createdAt)}}
            </v-tooltip>
          </template>

          <template #item.updatedAt='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.updatedAt)}}
                </div>
              </template>
              {{formattedDateInWords(item.updatedAt)}}
            </v-tooltip>
          </template>

          <template #item.actions='{ item }'>
            <v-menu
              bottom
              left
            >
              <template #activator='{ on, attrs }'>
                <v-btn
                  icon
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class='pa-0'>
                <v-list-item @click='handleOnClickEdit(item.id)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('EDIT_REGISTER')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='detailsDialog' fullscreen persistent no-click-animation>
      <v-card v-if='detailsDialog' style='background-color: var(--v-background-base);'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('CUSTOMER')}}
            </div>
          </v-app-bar>

          <customers-details :is-editing='isEditing' :customer-id='customerId' @new-customer='getData' @edit-customer='handleOnConfirmEdit' @close='detailsDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    name: 'Customers',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      CustomersDetails: () => import('./CustomersDetails'),
    },
    data: function () {
      return {
        list: undefined,
        search: undefined,
        customerTableHeaders: [
          { text: this.$t('NAME'), value: 'name' },
          { text: this.$t('CNPJ'), value: 'cnpj' },
          { text: this.$t('EMAIL'), value: 'email' },
          { text: this.$t('PHONE'), value: 'phone' },
          { text: this.$t('ACRONYM'), value: 'acronym' },
          { text: this.$t('FINGERPRINT_SIGNIN'), value: 'allowFingerprintSignin' },
          { text: this.$t('CREATION_DATE'), value: 'createdAt' },
          { text: this.$t('UPDATE_DATE'), value: 'updatedAt' },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '30',
            align: 'center',
          },
        ],
        customerId: 0,
        isEditing: false,
        detailsDialog: false,
        customerAccessDataDialog: false,
        selectedCustomer: undefined,
      };
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      handleOnClickEdit: function (id) {
        this.customerId = id;
        this.isEditing = true;
        this.detailsDialog = true;
      },
      handleOnConfirmEdit: function () {
        this.customerId = 0;
        this.isEditing = false;
        this.getData();
      },
      openCustomerAccessDataDialog: function (item) {
        this.selectedCustomer = item;
        this.customerAccessDataDialog = true;
      },
      closeCustomerAccessDataDialog: function () {
        this.customerAccessDataDialog = false;
        this.selectedCustomer = undefined;
      },
      formattedDate: function (value) {
        if (!value) return '';
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        if (!value) return '';
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      getTableRowClass: function (item) {
        if (!item.active) {
          return 'red lighten-3 grey--text text--darken-2';
        }

        return '';
      },
      getData: async function () {
        try {
          const { data } = await axios({
            url: '/customer',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
